import {bindable} from "aurelia-framework";
import {NitTools} from "../../classes/NursitTools";
import {qBase} from "./q-base";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {QuestionnaireResponse} from "../../classes/FhirModules/Fhir";
import {ConfigService} from "../../services/ConfigService";


export class qMultiSelect extends qBase {
    @bindable item;
    @bindable response;
    @bindable previousresponse: any;
    @bindable changed : Function;
    @bindable readonly;
    @bindable placeholder: string;
    @bindable onResponseItemValueChangedFromBehind : Function;
    
    options: IMultiSelectOption[];
    __itemValue: string[] = [];
    responseItem: any = undefined;
    closeDiv = true;
    displayItems: any[];
    showOverlay = true;
    clickDivId: string = "multiselect_" + NitTools.Uid();
    offsetLeft: number = 0;
    offsetTop: number = 0;
    width: number = 0;
    height: number = 0;
    parentDiv: HTMLDivElement = undefined;

    /**
     * returns the currently selected item linkIds as a string array
     */
    get itemValue(): string[] {
        this.__itemValue = this.__itemValue?.filter?.(this.onlyUnique)||[];
        return this.__itemValue;
    }

    /**
     * set the current item value from a string[]
     */
    set itemValue(value) {
        if (!value || !NitTools.IsArray(value)) value = [];
        this.__itemValue = value;
    }

    onItemValueChangeNotified(item: any) {
        super.onItemValueChangeNotified(item);
        this.responseChanged(this.response);
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    itemChanged(newVal) {
        if (!newVal) {
            this.options = [];
            this.displayItems = [];
            return;
        }

        this.createOptions();
        this.getResponseItem();
    }

    responseChanged(newVal) {
        this.itemValue = [];
        this.updateItemDisplay();

        if (!newVal) {
            this.responseItem = undefined;
            return;
        }

        this.getResponseItem();
    }

    onResponseItemValueChangedFromBehindChanged() { }

    answerDisplayItemClicked() {
        if (this.readonly) return;
        this.showOverlay = true;
    }

    updateItemDisplay() {
        this.displayItems = [];
        for (const value of this.itemValue)
        {
            let option = this.options?.find(o => o.value === value);
            if (option) {
                this.displayItems.push({text: option.text, value: option.value});
            }
        }
    }

    showOverlayWindow() {
        if (this.readonly) return;
        if (!this.showOverlay) {
            this.showOverlay = true;
            return;
        }

        const that = this;

        // get position
        let div = document.createElement("div");

        div.className = "multiselect-list-master";
        div.onclick = () => {
            that.updateItemDisplay();
            document.body.removeChild(div);
        };

        let parentDiv = document.createElement("div");
        parentDiv.className = "multiselect-list-parent";
        parentDiv.style.left = `${this.offsetLeft}px`;
        parentDiv.style.top = `${this.offsetTop}px`;
        parentDiv.style.width = `${this.width}px`;

        let ul: HTMLUListElement = document.createElement("ul");
        ul.className = "multiselect-list";

        if ((!that.itemValue || that.itemValue.length === 0) && (that.response && that.item?.linkId)) {
            // no checkbox has been set when popping up the list, although an initial value has been set in the questionnaire.
            // this could happen, when the value is set by FormEngine from the questionnaires initialValue
            // Compensate this by gathering the values from the initial answer and pushing it to the itemValue property
            const responseItem = QuestionnaireResponse.GetResponseItemByLinkId(that.response, that.item.linkId);
            if (NitTools.IsArray(responseItem?.answer) && responseItem.answer.length > 0) {
                if (ConfigService.Debug)
                    console.debug('No itemValue in Muliselect. Gathering value from Response');

                const codes = [];

                for (const answer of responseItem.answer) {
                    const code = QuestionnaireResponse.GetResponseAnswerValue(answer);
                    if (code)
                        codes.push(code);
                }

                that.itemValue = codes;
            }
        }

        // get the currently selected option and check if it is exclusive
        if (that.itemValue?.length === 1) {
            const selectedOption = that.options.find(o=>o.value === that.itemValue[0]);
            const isExclusive = selectedOption?.exclusive;

            // the selected value is exclusive, so disable all other items
            for (const option of that.options.filter(o=>o.value !== selectedOption?.value))
                option.isEnabled = !isExclusive;
        }

        for (const option of that.options.filter(o => !o.hidden))
        {
            let li = document.createElement("li");
            li.className = "multiselect-list-item";
            if (that.itemValue.indexOf(option.value) > -1) {
                li.classList.add("active");
            }

            if (!option.isEnabled) {
                li.classList.add("disabled");
            }

            li.innerText = option.text;
            li.title = option.hint;
            li.setAttribute("data-value", option.value);

            li.onclick = (event) => {
                event.cancelBubble = true;
                if (!option.isEnabled) return;

                const optionIsSelected = li.classList.contains("active");
                if (optionIsSelected) {  // unselecting an option:
                    li.classList.remove("active");
                    that.removeValue(option.value);

                    // special handling when the item is exclusive: enable all others items
                    if (option.exclusive) {
                        for (const itm of that.options.filter(o => o.value !== option.value)) {
                            itm.isEnabled = true;

                            ul.querySelectorAll('li') // set the items in html to enabled
                                .forEach(o => o.classList.remove('disabled'));
                        }
                    }
                } else { // set option to selected
                    // special handling when the item is exclusive: deselect all others and disable them
                    if (option.exclusive) {
                        that.itemValue = [];
                        ul.querySelectorAll(`li`) // remove the check from the li and disable it
                            .forEach(o => {
                                o.classList.remove('active');
                                o.classList.add('disabled');
                            });

                        for (const itm of that.options.filter(o => o.value !== option.value)) {
                            itm.isEnabled = false;
                        }
                    }

                    li.classList.add("active");
                    li.classList.remove("disabled"); // when it's clickable it is not disabled!
                    that.itemValue.push(option.value);
                }

                that.valueChanged();
            };

            ul.appendChild(li);
        }

        parentDiv?.appendChild(ul);
        div?.appendChild(parentDiv);
        that.parentDiv = parentDiv;

        document.body.appendChild(div);

        // move bottom into screen
        this.checkListPosition();
    }

    checkListPosition() {
        if (!this.parentDiv) return;
        let clickDiv = document.getElementById(this.clickDivId);
        if (!clickDiv) return;

        let $clickDiv = $(clickDiv);

        // let $ele = $(this.parentDiv);
        let pos = $clickDiv.offset();
        if (!pos) return;

        this.width = $clickDiv.width();
        this.height = this.parentDiv.clientHeight; // $ele.height();
        this.offsetTop = pos.top;
        this.offsetLeft = pos.left;

        if (document.body.clientHeight < (this.offsetTop + this.height + 20)) {
            this.offsetTop = document.body.clientHeight - this.height - 20;
        }

        this.parentDiv.style.top = `${this.offsetTop}px`;
        this.parentDiv.style.left = `${this.offsetLeft}px`;
        this.parentDiv.style.width = `${this.width}px`;
    }

    removeValue(value) {
        let idx = this.itemValue.indexOf(value);
        if (idx === -1) return;

        this.itemValue.splice(idx, 1);
        this.valueChanged();
    }

    getResponseItem() {
        if (!this.item || !this.response) {
            this.responseItem = undefined;
            return;
        }

        this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
        if (this.responseItem && this.responseItem.answer) {
            for (const answer of this.responseItem.answer)
            {
                if (answer.valueCoding && answer.valueCoding.code) {
                    this.itemValue.push(answer.valueCoding.code);
                    this.valueChanged();
                }
            }

            this.updateItemDisplay();
        }
    }

     updateItemValue(item : fhir4.QuestionnaireResponseItem) {
        if (!item) return;
            let txt = [];
            item.answer = [];
            
            if (typeof this.itemValue === "string") {
                let tmpString = String(this.itemValue);
                this.itemValue = tmpString.split(',');
                this.itemValue = this.itemValue.filter(o => !o.endsWith('_nil'));
            }

            if (this.itemValue[0] && this.itemValue[0].indexOf(',') > -1) {
                let tmpString = String(this.itemValue[0]);
                this.itemValue = tmpString.split(',');
                this.itemValue = this.itemValue.filter(o => o && !o.endsWith('_nil'));
            }

            for (const value of this.itemValue)
            {
                let textOption = this.options?.find(o => o.value === value);
                if (textOption && textOption.text) {
                    txt.push(textOption.text);
                }

                item.answer.push({
                    valueCoding: {
                        code: value,
                        display: textOption ? textOption.text : undefined
                    }
                });
            }
    }

    valueChanged() {
        this.updateItemValue(this.responseItem);
        
        const rItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item?.linkId, false);
        this.updateItemValue(rItem);

        if (typeof this.changed === "function") {
            this.changed(this.responseItem);
        }
    }

    createOptions() {
        if (!this.item || !this.item.option) return;

        this.options = [];

        for (const item of (<any[]>this.item.option).filter(o => o.valueCoding && o.valueCoding.code)) 
        {
            let isHidden: boolean = false;
            let isExclusive: boolean = false;
            if (item.extension) {
                let hiddenExt: any = item.extension.find(o => o.url.endsWith('questionnaire-hidden'));
                if (hiddenExt) {
                    isHidden = hiddenExt.valueBoolean;
                }

                let exclusiveExt: any = item.extension.find(o => o.url.endsWith('questionnaire-optionExclusive'));
                if (exclusiveExt) {
                    isExclusive = exclusiveExt.valueBoolean;
                }
            }

            let newOption: IMultiSelectOption = {
                value: item.valueCoding.code,
                text: item.valueCoding.display || "",
                hint: item.valueCoding.display || "",
                hidden: isHidden,
                exclusive: isExclusive,
                isEnabled: true
            };


            if (newOption.text.indexOf('|') > -1) {
                let arr = newOption.text.split('|');
                newOption.text = arr[0];
                newOption.hint = arr[1];
            }

            this.options.push(newOption);
        };
    }
}


export interface IMultiSelectOption {
    value: any;
    text: string;
    hint?: string;
    hidden?: boolean;
    exclusive: boolean;
    isEnabled: boolean;
}
